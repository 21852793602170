import React from 'react';

import { Layout } from '../../layouts';
import { Roadmap, References } from './components';

const Steps = () => {
    document.title = 'Enjoy Jobs | Aanpak';

    return (
        <Layout>
            <Roadmap />
            <References />
        </Layout>
    )
};

export default Steps;