import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Backoffice, Home, Login, Register, Steps, Vacancies, VacancyDetail, NotFound, Contact, Apply, ApplySucces, MessageSucces, Blog, Blogs, ForgottenPassword, SubmitReset, PrivacyPolicy, Disclaimer, Requirements } from './pages';
import { ToolboxProvider, APIProvider } from './services';

function App() {
  return (
    <ToolboxProvider>
      <APIProvider>
      <Router>
        <Switch basename="/">
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/requirements">
              <Requirements />
            </Route>
            <Route exact path="/disclaimer">
              <Disclaimer />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route exact path="/blogs/:page">
              <Blogs />
            </Route>
            <Route exact path="/blog/:id">
              <Blog />
            </Route>
            <Route exact path="/vacancies">
              <Vacancies />
            </Route>
            <Route exact path="/vacancy/:id">
              <VacancyDetail />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/profile">
              <Backoffice />
            </Route>
            <Route exact path="/contact/persoonlijkheidstest">
              <Contact
                isPersoonlijkheidstest={true}
              />
            </Route>
            <Route exact path="/contact-succes">
              <MessageSucces />
            </Route>
            <Route exact path="/profile">
              <Backoffice />
            </Route>
            <Route exact path="/roadmap">
              <Steps />
            </Route>
            <Route exact path="/apply">
              <Apply />
            </Route>
            <Route exact path="/apply-succes">
              <ApplySucces />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/forgotten-password">
              <ForgottenPassword />
            </Route>
            <Route exact path="/reset-password/:token">
              <SubmitReset />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
        </Switch>
      </Router>
      </APIProvider>
    </ToolboxProvider>
  );
}

export default App;
